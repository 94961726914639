import React from "react"

import Layout from "../layouts/layout"
import Seo from "../components/seo"

import Text from "../components/text"
import Headline from "../components/headline"
import Block from "../components/block"
import Logo from "../components/logo"

const DatenschutzPage = () => (
  <Layout>
    <Seo title="Datenschutz" />

    <Block>
      <Logo />
      <Headline text="Datenschutz" primary />
      <Text>
        <p>
          Ihre personenbezogenen Daten werden entsprechend den gesetzlichen
          Datenschutzvorschriften sowie dieser Datenschutzerklärung behandelt.
          Mit den nachfolgenden Informationen erhalten Sie Ihnen einen Überblick
          über die Verarbeitung Ihrer personenbezogenen Daten und Ihren Rechten
          aus dem Datenschutzrecht.
        </p>
      </Text>
    </Block>

    <Block id="text">
      <Text left>
        <h3>
          1. Wer ist für die Datenverarbeitung verantwortlich und an wen kann
          ich mich wenden?
        </h3>
        <p>
          Hagen Hansen
          <br />
          Paetowweg 6<br />
          18347 Ostseebad Ahrenshoop
          <br />
          Deutschland
          <br />
          Telefon: <a href="tel:+49382206677-0">+49 38220 6677-0</a>
          <br />
          Fax: +49 38220 6677-22
          <br />
          E-Mail:{" "}
          <a href="mailto:info@hagenhansen.de">info [at] hagenhansen.de</a>
        </p>
        <h3>2. Für wen gilt diese Datenschutzerklärung?</h3>
        <p>
          In dieser Datenschutzerklärung geht es um die Besucher unserer
          Website, um unsere Kunden und Interessenten, die sich über unser
          Leistungsprofil, unsere Services und die Möglichkeit einer
          Kontaktaufnahme informieren möchten.
        </p>
        <h3>3. Welche Daten nutzen wir?</h3>
        <p>
          Sie können unsere Webseiten grundsätzlich besuchen, ohne uns Ihre
          Identität mitzuteilen. Wenn Sie uns eine E-Mail oder ein
          Kontaktformular senden, werden Ihre Nachricht und Ihre E-Mail-Adresse
          ausschließlich für die Korrespondenz mit Ihnen verwendet.
        </p>
        <h3>
          4. Für welche Zwecke und auf welcher Rechtsgrundlage nutzen wir Ihre
          Daten?
        </h3>
        <p>
          Wir verarbeiten Ihre personenbezogenen Daten im Einklang mit den
          Bestimmungen der Datenschutz-Grundverordnung (EU DS-GVO) und dem
          Bundesdatenschutzgesetz (BDSG). Beachten Sie dazu auch unsere
          Information über Ihr Widerspruchsrecht nach Artikel 21 EU DS-GVO. a)
          Zur Erfüllung von vertraglichen Pflichten (Art. 6 Abs.1b EU DS-GVO) b)
          Aufgrund Ihrer Einwilligung (Art. 6 Abs. 1a EU DS-GVO) Soweit Sie uns
          eine Einwilligung zur Verarbeitung von personenbezogenen Daten für
          bestimmte Zwecke (z. B. Terminvereinbarung, Produktbestellung usw.)
          erteilt haben, ist die Rechtmäßigkeit dieser Verarbeitung auf Basis
          Ihrer Einwilligung gegeben. Eine erteilte Einwilligung kann jederzeit
          widerrufen werden. Bitte beachten Sie, dass der Widerruf erst für die
          Zukunft wirkt. Verarbeitungen, die vor dem Widerruf erfolgt sind, sind
          davon nicht betroffen. d) Aufgrund gesetzlicher Vorgaben (Art. 6
          Abs.1c DSGVO) oder im öffentlichen Interesse (Art. 6 Abs.1e DSGVO)
          Zudem unterliegen wir als Unternehmen diversen rechtlichen
          Verpflichtungen (z. B. Handwerkskammer, Steuergesetze).
        </p>
        <h3>5. Wer bekommt meine Daten?</h3>
        <p>
          Innerhalb von HAGEN HANSEN Designbüro erhalten diejenigen Stellen
          Zugriff auf Ihre Daten, die diese zur Erfüllung unserer vertraglichen
          und gesetzlichen Pflichten brauchen.
        </p>
        <h3>6. Werden Daten in ein Drittland übermittelt?</h3>
        <p>
          Eine Datenübermittlung in Länder außerhalb der EU bzw. des EWR
          (sogenannte Drittstaaten) findet nicht statt.
        </p>
        <h3>7. Wie lange werden meine Daten gespeichert?</h3>
        <p>
          Wir verarbeiten und speichern Ihre personenbezogenen Daten solange es
          für die Erfüllung unserer vertraglichen und gesetzlichen Pflichten
          erforderlich ist. Sind die Daten für die Erfüllung vertraglicher oder
          gesetzlicher Pflichten nicht mehr erforderlich, werden diese
          regelmäßig gelöscht, es sei denn, deren - befristete -
          Weiterverarbeitung ist zur Erfüllung von Aufbewahrungsfristen
          erforderlich, wie beispielsweise dem Bürgerlichen Gesetzbuch (BGB).
          Die dort vorgegebenen Fristen zur Aufbewahrung bzw. Dokumentation
          betragen z. B. drei Jahre.
        </p>
        <h3>8. Welche Datenschutzrechte habe ich?</h3>
        <p>
          Sie haben das Recht auf Auskunft nach Artikel 15 EU DS-GVO, das Recht
          auf Berichtigung nach Artikel 16 EU DS-GVO, das Recht auf Löschung
          nach Artikel 17 EU DS-GVO, das Recht auf Einschränkung der
          Verarbeitung nach Artikel 18 EU DS-GVO, das Recht auf Widerspruch aus
          Artikel 21 EU DS-GVO sowie das Recht auf Datenübertragbarkeit aus
          Artikel 20 EU DS-GVO. Darüber hinaus besteht ein Beschwerderecht bei
          einer Datenschutzaufsichtsbehörde (Artikel 77 EU DS-GVO i.V.m. § 19
          BDSG). Eine Liste der Aufsichtsbehörden sowie deren Kontaktdaten
          können »hier« entnommen werden. Eine erteilte Einwilligung in die
          Verarbeitung personenbezogener Daten können Sie jederzeit uns
          gegenüber widerrufen. Bitte beachten Sie, dass der Widerruf erst für
          die Zukunft wirkt. Verarbeitungen, die vor dem Widerruf erfolgt sind,
          sind davon nicht betroffen. Beachten Sie dazu auch unsere Information
          über Ihr Widerspruchsrecht nach Artikel 21 DS-GVO. Für die Ausübung
          Ihrer Rechte nutzen Sie bitte den o. g. Kontakt.
        </p>
        <h3>9. Gibt es eine Pflicht zur Bereitstellung von Daten?</h3>
        <p>
          Im Rahmen der Bekundung einer Terminvereinbarung oder
          Nutzungsinteresses von Services (wie beispielsweise Produktbestellung)
          müssen Sie Ihre personenbezogenen Daten bereitstellen, die für
          Durchführung und Erfüllung der damit verbundenen vertraglichen Rechte
          und Pflichten erforderlich sind oder zu deren Erhebung wir sogar
          gesetzlich verpflichtet sind. Ohne diese Daten müssen wir in der Regel
          den Abschluss des Vertrages oder die Ausführung eines Auftrages
          ablehnen, oder können einen bestehenden Vertrag nicht mehr durchführen
          bzw. müssen diesen ggf. beenden.
        </p>
        <h3>
          10. Gibt es eine automatisierte Entscheidungsfindung einschließlich
          Profiling?
        </h3>
        <p>
          Wir nutzen grundsätzlich keine vollautomatisierte Entscheidungsfindung
          einschließlich Profiling gemäß Artikel 22 EU DS-GVO. Für den Fall,
          dass wir Profiling für gezieltes Marketing einsetzen, werden Sie dazu
          gesondert informiert.
        </p>
        <h3>
          11. Information über Ihr Widerspruchsrecht nach Artikel 21 EU DS-GVO
          a)
        </h3>
        <p>
          Einzelfallbezogenes Widerspruchsrecht Sie haben das Recht, aus
          Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die
          Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen.
          Voraussetzung hierfür ist, dass die Datenverarbeitung im öffentlichen
          Interesse oder auf der Grundlage einer Interessenabwägung erfolgt.
          Dies gilt auch für ein Profiling. Im Falle eines Widerspruchs werden
          wir Ihre personenbezogenen Daten nicht mehr verarbeiten. Es sei denn,
          wir können zwingende schutzwürdige Gründe für die Verarbeitung dieser
          Daten nachweisen, die Ihren Interessen, Rechten und Freiheiten
          überwiegen. Oder Ihre personenbezogenen Daten dienen der
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. b)
          Widerspruch gegen die Verarbeitung Ihrer Daten für Direktwerbung In
          Einzelfällen nutzen wir Ihre personenbezogenen Daten für unsere
          Direktwerbung. Sie haben das Recht, jederzeit Widerspruch dagegen
          einzulegen; dies gilt auch für das Profiling, wenn es mit einer
          Direktwerbung in Verbindung steht. Im Falle eines Widerspruchs
          verarbeiten wir Ihre personenbezogenen Daten nicht mehr für diese
          Zwecke. Der Widerspruch kann formfrei erfolgen und sollte möglichst an
          den o.g. Kontakt gerichtet werden.
        </p>
        <h3>
          12. Welche Daten werden bei der Nutzung der Webseite verarbeitet?
        </h3>
        <p>
          a) Nutzungsbezogene Angaben Durch den Aufruf unserer Webseiten
          erhalten wir Nutzungsdaten. Dazu gehören Angaben wie z.B. zur
          Bildschirmauflösung, zur Browserversion, zum Internetzugang, zum
          Betriebssystem, zur Sprache, zu den eingesetzten PlugIns, zur Herkunft
          nach Land/Region und zu Suchmaschinen. Die gespeicherten Daten werden
          nur zu statistischen Zwecken ausgewertet. Eine Weitergabe an Dritte
          und eine nutzerbezogene Auswertung findet nicht statt.
        </p>
        <p>
          b) Einsatz von Cookies Beim Aufruf einzelner Seiten werden so genannte
          Session Cookies verwendet, um die Navigation zu erleichtern. Diese
          Cookies verfallen nach Ablauf der Sitzung und beinhalten keine
          personenbezogenen Daten, d.h. die Inhalte der Cookies werden nicht
          nutzerbezogen ausgewertet. Sie können Ihren Browser so einstellen,
          dass Sie Cookies nur im Einzelfall oder gar nicht erlauben. Cookies,
          die zur Durchführung des elektronischen Kommunikationsvorgangs oder
          zur Bereitstellung bestimmter Funktionen (z.B. Registrierung,
          Anmeldung, Zahlungsabwicklung) erforderlich sind, werden auf Grundlage
          von Art. 6 Abs. 1 lit. f EU DS-GVO gespeichert. Die Speicherung von
          Cookies erfolgt in diesem Fall für den Zweck der technisch
          fehlerfreien und optimierten Bereitstellung unserer Dienste. Bei der
          Deaktivierung von Cookies kann die Funktionalität dieser Webseite
          eingeschränkt sein.
        </p>
        <h3>13. Wie sicher sind meine Daten?</h3>
        <p>
          Zum Schutz der personenbezogenen Daten unserer Kunden und
          Interessenten benutzen wir ein sicheres Online-Übertragungsverfahren,
          die so genannte "Secure Socket Layer" (SSL)-Übertragung. Alle mit
          dieser sicheren Methode übertragenen Informationen werden
          verschlüsselt, bevor sie gesendet werden. Ihre personenbezogenen Daten
          werden ausschließlich auf durch Sicherheitstechnologien geschützten,
          dem Industriestandard entsprechenden (z. B. Firewalls, Passwortschutz,
          Zutrittskontrollen etc.) Rechenzentren und Rechnern verarbeitet.
        </p>
        <p>© HAGEN HANSEN DESIGNBÜRO</p>
      </Text>
    </Block>
  </Layout>
)

export default DatenschutzPage
