import React from "react"
import styled from "styled-components"
import theme from "../theme"

import logo from "../images/hh-logo.svg"

const LogoContainer = styled.div`
  margin: 0.5em 0;

  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
`

const LogoImage = styled.div`
  width: calc(50 / 16 * 1rem);

  @media (min-width: ${theme.breakpoint.large}) {
    width: calc(150 / 16 * 1rem);
  }

  img {
    width: 100%;
    height: auto;
  }
`

const Logo = (props) => {
  return (
    <LogoContainer>
      <LogoImage>
        <img src={logo} alt="Logo" />
      </LogoImage>
    </LogoContainer>
  )
}

export default Logo
